import { render, staticRenderFns } from "./TheHero.vue?vue&type=template&id=7d24870a&scoped=true&"
import script from "./TheHero.vue?vue&type=script&lang=js&"
export * from "./TheHero.vue?vue&type=script&lang=js&"
import style0 from "./TheHero.vue?vue&type=style&index=0&id=7d24870a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d24870a",
  null
  
)

export default component.exports