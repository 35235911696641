<template>
  <svg class="hero-logo" viewBox="0 0 315.69 141.61">
    <title>logo</title>
    <g>
      <path
        class="a"
        d="M33.23,117.75a3,3,0,0,0-2.95,3v18a2.95,2.95,0,0,0,5.9,0v-18A3,3,0,0,0,33.23,117.75Z"
      />
      <path
        class="a"
        d="M48.81,117.75a3,3,0,0,0-3,3v18a2.95,2.95,0,1,0,5.89,0v-18A3,3,0,0,0,48.81,117.75Z"
      />
      <path
        class="a"
        d="M114.69,117.75a3,3,0,0,0-3,3v18a2.95,2.95,0,0,0,5.9,0v-18A3,3,0,0,0,114.69,117.75Z"
      />
      <path
        class="a"
        d="M130.27,117.75a3,3,0,0,0-3,3v18a2.95,2.95,0,1,0,5.9,0v-18A3,3,0,0,0,130.27,117.75Z"
      />
      <path
        class="a"
        d="M267.53,117.75a3,3,0,0,0-2.95,3v18a2.95,2.95,0,1,0,5.89,0v-18A3,3,0,0,0,267.53,117.75Z"
      />
      <path
        class="a"
        d="M288.19,135.71h-2.71v-15a2.95,2.95,0,0,0-5.9,0v18a3,3,0,0,0,2.95,3h5.66a2.95,2.95,0,0,0,0-5.9Z"
      />
      <path
        class="a"
        d="M191.25,131.27a2.94,2.94,0,0,0-2.94,2.94v4.45a2.95,2.95,0,1,0,5.89,0v-4.45A3,3,0,0,0,191.25,131.27Z"
      />
      <path
        class="a"
        d="M209.07,131.27a3,3,0,0,0-2.95,2.94v4.45a2.95,2.95,0,0,0,5.9,0v-4.45A3,3,0,0,0,209.07,131.27Z"
      />
      <path
        class="a"
        d="M91.41,118l-21.77,8.71a2.95,2.95,0,0,0,1.09,5.69,3,3,0,0,0,1.09-.21l8.26-3.31v9.82a2.95,2.95,0,0,0,5.9,0V126.48l7.61-3A2.95,2.95,0,1,0,91.41,118Z"
      />
      <path
        class="a"
        d="M211.57,125.53a3,3,0,0,0-2.95-3h-5.51v-3a2.95,2.95,0,0,0-5.9,0v3h-5.64a3,3,0,1,0,0,5.9h5.64v10.18a2.95,2.95,0,0,0,5.9,0V128.48h5.51A3,3,0,0,0,211.57,125.53Z"
      />
      <path
        class="a"
        d="M167,126.83H156.3a3,3,0,0,0,0,5.9H167a2.95,2.95,0,1,0,0-5.9Z"
      />
      <path
        class="a"
        d="M243.84,126.83H233.16a3,3,0,1,0,0,5.9h10.68a3,3,0,0,0,0-5.9Z"
      />
      <path
        class="a"
        d="M142.35,109.09a7,7,0,1,0,7,7A7,7,0,0,0,142.35,109.09Zm0,9.28a2.28,2.28,0,1,1,2.28-2.28A2.28,2.28,0,0,1,142.35,118.37Z"
      />
      <path class="a" d="M218.35,113.14a3,3,0,0,0-2.95,3h0a3,3,0,1,0,2.95-3Z" />
      <path class="a" d="M209.07,113.14a3,3,0,0,0-2.95,3h0a3,3,0,1,0,2.95-3Z" />
      <path
        class="a"
        d="M265.26,1.47c-27.86,0-50.43,23.1-50.43,51.58s22.57,51.58,50.43,51.58,50.43-23.09,50.43-51.58S293.11,1.47,265.26,1.47Zm0,84.7c-17.89,0-32.38-14.83-32.38-33.12s14.49-33.11,32.38-33.11,32.38,14.82,32.38,33.11S283.14,86.17,265.26,86.17Z"
      />
      <path
        class="a"
        d="M102.14,1.46a9.08,9.08,0,0,0-9.17,9V95.65a9.17,9.17,0,0,0,18.34,0V10.43A9.07,9.07,0,0,0,102.14,1.46Z"
      />
      <path
        class="a"
        d="M80.57,65.82A40.2,40.2,0,0,0,64.2,33.41a25.27,25.27,0,1,0-47.83,0,40.28,40.28,0,1,0,64.2,32.41ZM33.38,25.14a6.9,6.9,0,0,1,13.8,0,8.09,8.09,0,0,1-.07,1,6.9,6.9,0,0,1-13.65,0A6.5,6.5,0,0,1,33.38,25.14Zm6.9,62.91A22.15,22.15,0,1,1,62.44,65.9,22.15,22.15,0,0,1,40.28,88.05Z"
      />
      <path
        class="a"
        d="M164.1,106.11A40.28,40.28,0,0,0,188,33.41a25.27,25.27,0,1,0-47.83,0,40.28,40.28,0,0,0,23.91,72.7Zm0-18.06A22.15,22.15,0,1,1,186.26,65.9,22.15,22.15,0,0,1,164.1,88.05Zm-6.9-62.91a6.9,6.9,0,0,1,13.8,0,6.5,6.5,0,0,1-.08,1,6.89,6.89,0,0,1-13.64,0A6.5,6.5,0,0,1,157.2,25.14Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HeroLogo',
}
</script>

<style lang="scss" scoped>
.hero-logo {
  @include overlay;
  //
  .a {
    fill: $color-white;
  }
}
</style>
