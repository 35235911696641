import { render, staticRenderFns } from "./HeroLogo.vue?vue&type=template&id=48971000&scoped=true&"
import script from "./HeroLogo.vue?vue&type=script&lang=js&"
export * from "./HeroLogo.vue?vue&type=script&lang=js&"
import style0 from "./HeroLogo.vue?vue&type=style&index=0&id=48971000&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48971000",
  null
  
)

export default component.exports